import Cookies from 'js-cookie'
import router from '@/router/router'

const state = {
  auth: false,
  token: null,
  forgotPasswordEmail: null,
}
const getters = {
  authGetter: ({ auth }) => auth,
  tokenGetter: ({ token }) => token,
}

const mutations = {
  setAuth: (state, payload) => (state.auth = payload),
  setToken: (state, payload) => (state.token = payload),
  setForgotPasswordEmail: (state, payload) => (state.forgotPasswordEmail = payload),
}

const actions = {
  login({ commit, dispatch }, payload) {
    this.$axios.post('login', payload).then((res) => {
      dispatch('setNewToken', res.data.result)
      commit('setAuth', true)
      router.push('/')
    })
  },
  logout({ commit }) {
    this.$axios.post('logout').then((res) => {
      commit('setAuth', false)
      Cookies.remove('token')
      router.push('/login')
    })
  },
  forgotPassword({ commit }, payload) {
    this.$axios.post('forgot-password', payload).then((res) => {
      commit('setForgotPasswordEmail', payload.email)
      router.push('/reset-password')
    })
  },
  resetPassword({ commit }, payload) {
    this.$axios.post('reset-password', payload).then((res) => {
      router.push('/login')
    })
  },
  setNewToken({ commit }, newToken) {
    const oneDay = 24 * 60 * 60 * 1000
    const dateNow = new Date()
    const expire = new Date(newToken.expired_at)
    const remainingDays = Math.round(Math.abs((expire - dateNow) / oneDay))
    Cookies.remove('token')
    Cookies.set('token', newToken.token, { expires: remainingDays })
  },
}
export default { state, getters, mutations, actions }
