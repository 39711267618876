const state = {
  questionCategories: [],
}
const getters = {
  questionCategoriesGetter: ({ questionCategories }) => questionCategories,
}

const mutations = {
  setQuestionCategories: (state, payload) => (state.questionCategories = payload),
  setQuestionCategory: (state, payload) => state.questionCategories.push(payload),
  setUpdateQuestionCategory: (state, payload) => {
    state.questionCategories = state.questionCategories.map((questionCategory) => {
      if (questionCategory.id === payload.id) {
        return Object.assign({}, questionCategory, payload)
      }
      return questionCategory
    })
  },
  setDeleteQuestionCategory: (state, payload) => {
    state.questionCategories = state.questionCategories.filter((questionCategory) => questionCategory.id !== payload)
  },
}

const actions = {
  async createQuestionCategory({ commit }, payload) {
    await this.$axios.post('question-categories', payload).then((res) => {
      commit('setQuestionCategory', res.data.result)
    })
  },
  async getQuestionCategories({ commit }, payload) {
    await this.$axios.get('question-categories', { params: { ...payload } }).then((res) => {
      commit('setQuestionCategories', res.data.result)
    })
  },
  async updateQuestionCategory({ commit }, payload) {
    await this.$axios.put(`question-categories/${payload.id}`, payload).then((res) => {
      commit('setUpdateQuestionCategory', payload)
    })
  },
  deleteQuestionCategory({ commit }, payload) {
    this.$axios.delete(`question-categories/${payload}`).then((res) => {
      commit('setDeleteQuestionCategory', payload)
    })
  },
}
export default { state, getters, mutations, actions }
