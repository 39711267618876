const state = {
  profile: [],
}

const getters = {
  profileGetter: ({ profile }) => profile,
}

const mutations = {
  setProfile: (state, payload) => (state.profile = payload),
}

const actions = {
  async getProfile({ commit }) {
    await this.$axios.get('profile/me').then((res) => {
      commit('setProfile', res.data.result.user)
    })
  },
  async updateProfile({ commit }, payload) {
    await this.$axios.put('profile/me', payload).then((res) => {
      console.log(res)
    })
  },
  async changePassword({ commit }, payload) {
    await this.$axios.post('profile/change-password', payload).then((res) => {
      console.log(res)
    })
  },
}
export default { state, getters, mutations, actions }
