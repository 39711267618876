const state = {
  withdrawalRequests: [],
  withdrawalRequestsMetaData: [],
}
const getters = {
  withdrawalRequestsGetter: ({ withdrawalRequests }) => withdrawalRequests,
  withdrawalRequestsMetaData: ({ withdrawalRequestsMetaData }) => withdrawalRequestsMetaData,
}

const mutations = {
  setWithdrawalRequests: (state, payload) => (state.withdrawalRequests = payload),
  setWithdrawalRequestsMetaData: (state, payload) => (state.withdrawalRequestsMetaData = payload),
  setApproveWithdrawalRequest: (state, payload) => console.log(payload),
  setDeclineWithdrawalRequest: (state, payload) => {
    state.withdrawalRequests = state.withdrawalRequests.filter((withdrawalRequest) => withdrawalRequest.id !== payload)
  },
}

const actions = {
  async getWithdrawalRequests({ commit }, payload) {
    await this.$axios.get('wallets/withdraw-requests', { params: { ...payload } }).then((res) => {
      commit('setWithdrawalRequests', res.data.result.items)
      commit('setWithdrawalRequestsMetaData', res.data.result.metadata)
    })
  },
  approveWithdrawalRequest({ commit }, payload) {
    this.$axios.post(`wallets/withdraw-requests/approve/${payload.id}/${payload.user_id}`, payload).then((res) => {
      commit('setApproveWithdrawalRequest', payload)
    })
  },
  declineWithdrawalRequest({ commit }, payload) {
    this.$axios.post(`wallets/withdraw-requests/decline/${payload.id}`, payload).then((res) => {
      commit('setDeclineWithdrawalRequest', payload)
    })
  },
}
export default { state, getters, mutations, actions }
