<template>
  <Toast />
  <router-view></router-view>
</template>

<script>
import EventBus from './AppEventBus'
import { useStore } from 'vuex'
import { computed, watch, getCurrentInstance } from 'vue'

export default {
  setup() {
    const store = useStore()
    const messageGetter = computed(() => store.getters.messageGetter)

    watch(messageGetter, (val) => {
      if (val) {
        store.$toast.add({
          severity: messageGetter.value.type,
          summary: messageGetter.value.type.toUpperCase(),
          detail: messageGetter.value.message,
          life: 3000,
        })
      }
      store.commit('setMessage', null)
    })
    return {
      messageGetter,
    }
  },
  themeChangeListener: null,
  mounted() {
    this.themeChangeListener = (event) => {
      const elementId = 'theme-link'
      const linkElement = document.getElementById(elementId)
      const cloneLinkElement = linkElement.cloneNode(true)
      const newThemeUrl = linkElement.getAttribute('href').replace(this.$appState.theme, event.theme)

      cloneLinkElement.setAttribute('id', elementId + '-clone')
      cloneLinkElement.setAttribute('href', newThemeUrl)
      cloneLinkElement.addEventListener('load', () => {
        linkElement.remove()
        cloneLinkElement.setAttribute('id', elementId)
      })
      linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling)

      this.$appState.theme = event.theme
      this.$appState.darkTheme = event.dark
    }

    EventBus.on('theme-change', this.themeChangeListener)
  },
  beforeUnmount() {
    EventBus.off('theme-change', this.themeChangeListener)
  },
}
</script>