const state = {
  teams: [],
  teamMetaData: [],
}
const getters = {
  teamsGetter: ({ teams }) => teams,
  teamMetaDataGetter: ({ teamMetaData }) => teamMetaData,
}

const mutations = {
  setTeams: (state, payload) => (state.teams = payload),
  setBulkTeams: (state, payload) => state.teams.push(...payload),
  setTeam: (state, payload) => state.teams.push(payload),
  setTeamMetaData: (state, payload) => (state.teamMetaData = payload),
  setUpdateTeam: (state, payload) => {
    state.teams = state.teams.map((team) => {
      if (team.id === payload.id) {
        return Object.assign({}, team, payload)
      }
      return team
    })
  },
  setDeleteTeam: (state, payload) => {
    state.teams = state.teams.filter((team) => team.id !== payload)
  },
}

const actions = {
  async createTeam({ commit }, payload) {
    await this.$axios.post('teams', payload).then((res) => {
      commit('setTeam', { ...res.data.result, places: payload.places })
    })
  },
  async getTeams({ commit }, payload) {
    const filters = payload && payload.filters ? { ...payload.filters } : {}
    await this.$axios.get('teams', { params: { ...filters } }).then((res) => {
      commit('setTeamMetaData', res.data.result.metadata)
      if (payload && payload.bulk) commit('setBulkTeams', res.data.result.items)
      else commit('setTeams', res.data.result.items)
    })
  },
  async updateTeam({ commit }, payload) {
    await this.$axios.put(`teams/${payload.id}`, payload).then((res) => {
      commit('setUpdateTeam', payload)
    })
  },
  updateTeamRoles({ commit }, payload) {
    console.log(payload.params)
    this.$axios.put(`teams/${payload.id}/roles`, {}, { params: payload.params }).then((res) => {})
  },
  deleteTeam({ commit }, payload) {
    this.$axios.delete(`teams/${payload}`).then((res) => {
      commit('setDeleteTeam', payload)
    })
  },
}
export default { state, getters, mutations, actions }
