const state = {
  campaigns: [],
  campaignMetaData: [],
}
const getters = {
  campaignsGetter: ({ campaigns }) => campaigns,
  campaignMetaDataGetter: ({ campaignMetaData }) => campaignMetaData,
}

const mutations = {
  setCampaigns: (state, payload) => (state.campaigns = payload),
  setCampaign: (state, payload) => state.campaigns.push(payload),
  setCampaignMetaData: (state, payload) => (state.campaignMetaData = payload),
  setUpdateCampaign: (state, payload) => {
    state.campaigns = state.campaigns.map((campaign) => {
      if (campaign.id === payload.id) {
        return Object.assign({}, campaign, payload)
      }
      return campaign
    })
  },
  setDeleteCampaign: (state, payload) => {
    state.campaigns = state.campaigns.filter((campaign) => campaign.id !== payload)
  },
}

const actions = {
  async createCampaign({ commit }, payload) {
    await this.$axios.post('campaigns', payload).then((res) => {
      commit('setCampaign', res.data.result)
    })
  },
  async getCampaigns({ commit }, payload) {
    await this.$axios.get('campaigns', { params: { ...payload } }).then((res) => {
      commit('setCampaignMetaData', res.data.result.metadata)
      commit('setCampaigns', res.data.result.items)
    })
  },
  async updateCampaign({ commit }, payload) {
    await this.$axios.put(`campaigns/${payload.id}`, payload).then((res) => {
      commit('setUpdateCampaign', payload)
    })
  },
  deleteCampaign({ commit }, payload) {
    this.$axios.delete(`campaigns/${payload}`).then((res) => {
      commit('setDeleteCampaign', payload)
    })
  },
}
export default { state, getters, mutations, actions }
