<template>
  <div ref="container" :class="containerClass" @click="onContainerClick">
    <div class="p-hidden-accessible">
      <input
        :id="inputId"
        ref="focusInput"
        type="text"
        readonly
        :disabled="disabled"
        :placeholder="placeholder"
        :tabindex="!disabled ? tabindex : -1"
        role="combobox"
        :aria-label="ariaLabel"
        :aria-labelledby="ariaLabelledby"
        aria-haspopup="listbox"
        :aria-expanded="overlayVisible"
        :aria-controls="id + '_list'"
        :aria-activedescendant="focused ? focusedOptionId : undefined"
        v-bind="inputProps"
        @focus="onFocus"
        @blur="onBlur"
        @keydown="onKeyDown"
      />
    </div>

    <div class="p-multiselect-label-container">
      <div :class="labelClass">
        <slot name="value" :value="modelValue" :placeholder="placeholder">
          <template v-if="display === 'comma'">
            {{ label || 'empty' }}
          </template>
          <template v-else-if="display === 'chip'">
            <div v-for="item of modelValue" :key="getLabelByValue(item)" class="p-multiselect-token">
              <slot name="chip" :value="item">
                <span class="p-multiselect-token-label">{{ getLabelByValue(item) }}</span>
              </slot>
              <span v-if="!disabled" class="p-multiselect-token-icon pi pi-times-circle" @click="removeOption($event, item)"></span>
            </div>
            <template v-if="!modelValue || modelValue.length === 0">{{ placeholder || 'empty' }}</template>
          </template>
        </slot>
      </div>
    </div>
    <div class="p-multiselect-trigger">
      <slot name="indicator">
        <span :class="dropdownIconClass" aria-hidden="true"></span>
      </slot>
    </div>
    <Portal :append-to="appendTo">
      <transition
        name="p-connected-overlay"
        @enter="onOverlayEnter"
        @after-enter="onOverlayAfterEnter"
        @leave="onOverlayLeave"
        @after-leave="onOverlayAfterLeave"
      >
        <div
          v-if="overlayVisible"
          :ref="overlayRef"
          :style="panelStyle"
          :class="panelStyleClass"
          v-bind="panelProps"
          @click="onOverlayClick"
          @keydown="onOverlayKeyDown"
        >
          <span
            ref="firstHiddenFocusableElementOnOverlay"
            role="presentation"
            aria-hidden="true"
            class="p-hidden-accessible p-hidden-focusable"
            :tabindex="0"
            @focus="onFirstHiddenFocus"
          ></span>
          <slot name="header" :value="modelValue" :options="visibleOptions"></slot>
          <div v-if="(showToggleAll && selectionLimit == null) || filter" class="p-multiselect-header">
            <div v-if="showToggleAll && selectionLimit == null" :class="headerCheckboxClass" @click="onToggleAll">
              <div class="p-hidden-accessible">
                <input
                  type="checkbox"
                  readonly
                  :checked="allSelected"
                  :aria-label="toggleAllAriaLabel"
                  @focus="onHeaderCheckboxFocus"
                  @blur="onHeaderCheckboxBlur"
                />
              </div>
              <div :class="['p-checkbox-box', { 'p-highlight': allSelected, 'p-focus': headerCheckboxFocused }]">
                <span :class="['p-checkbox-icon', { 'pi pi-check': allSelected }]"></span>
              </div>
            </div>
            <div v-if="filter" class="p-multiselect-filter-container">
              <input
                ref="filterInput"
                type="text"
                :value="filterValue"
                class="p-multiselect-filter p-inputtext p-component"
                :placeholder="filterPlaceholder"
                role="searchbox"
                autocomplete="off"
                :aria-owns="id + '_list'"
                :aria-activedescendant="focusedOptionId"
                v-bind="filterInputProps"
                @vnode-updated="onFilterUpdated"
                @keydown="onFilterKeyDown"
                @blur="onFilterBlur"
                @input="onFilterChange"
              />
              <span class="p-multiselect-filter-icon pi pi-search"></span>
            </div>
            <span v-if="filter" role="status" aria-live="polite" class="p-hidden-accessible">
              {{ filterResultMessageText }}
            </span>
            <button
              v-ripple
              class="p-multiselect-close p-link"
              :aria-label="closeAriaLabel"
              type="button"
              v-bind="closeButtonProps"
              @click="onCloseClick"
            >
              <span class="p-multiselect-close-icon pi pi-times" />
            </button>
          </div>
          <div
            class="p-multiselect-items-wrapper"
            :style="{ 'max-height': virtualScrollerDisabled ? scrollHeight : '' }"
            @scroll="handleWrapperScroll"
          >
            <VirtualScroller
              :ref="virtualScrollerRef"
              v-bind="virtualScrollerOptions"
              :items="visibleOptions"
              :style="{ height: scrollHeight }"
              :tabindex="-1"
              :disabled="virtualScrollerDisabled"
            >
              <template #content="{ styleClass, contentRef, items, getItemOptions, contentStyle, itemSize }">
                <ul
                  :id="id + '_list'"
                  :ref="(el) => listRef(el, contentRef)"
                  :class="['p-multiselect-items p-component', styleClass]"
                  :style="contentStyle"
                  role="listbox"
                  aria-multiselectable="true"
                >
                  <template v-for="(option, i) of items" :key="getOptionRenderKey(option, getOptionIndex(i, getItemOptions))">
                    <li
                      v-if="isOptionGroup(option)"
                      :id="id + '_' + getOptionIndex(i, getItemOptions)"
                      :style="{ height: itemSize ? itemSize + 'px' : undefined }"
                      class="p-multiselect-item-group"
                      role="option"
                    >
                      <slot name="optiongroup" :option="option.optionGroup" :index="getOptionIndex(i, getItemOptions)">{{
                        getOptionGroupLabel(option.optionGroup)
                      }}</slot>
                    </li>
                    <li
                      v-else
                      :id="id + '_' + getOptionIndex(i, getItemOptions)"
                      v-ripple
                      :style="{ height: itemSize ? itemSize + 'px' : undefined }"
                      :class="[
                        'p-multiselect-item',
                        {
                          'p-highlight': isSelected(option),
                          'p-focus': focusedOptionIndex === getOptionIndex(i, getItemOptions),
                          'p-disabled': isOptionDisabled(option),
                        },
                      ]"
                      role="option"
                      :aria-label="getOptionLabel(option)"
                      :aria-selected="isSelected(option)"
                      :aria-disabled="isOptionDisabled(option)"
                      :aria-setsize="ariaSetSize"
                      :aria-posinset="getAriaPosInset(getOptionIndex(i, getItemOptions))"
                      @click="onOptionSelect($event, option, getOptionIndex(i, getItemOptions), true)"
                      @mousemove="onOptionMouseMove($event, getOptionIndex(i, getItemOptions))"
                    >
                      <div class="p-checkbox p-component">
                        <div :class="['p-checkbox-box', { 'p-highlight': isSelected(option) }]">
                          <span :class="['p-checkbox-icon', { 'pi pi-check': isSelected(option) }]"></span>
                        </div>
                      </div>
                      <slot name="option" :option="option" :index="getOptionIndex(i, getItemOptions)">
                        <span>{{ getOptionLabel(option) }}</span>
                      </slot>
                    </li>
                  </template>
                  <li v-if="filterValue && (!items || (items && items.length === 0))" class="p-multiselect-empty-message" role="option">
                    <slot name="emptyfilter">{{ emptyFilterMessageText }}</slot>
                  </li>
                  <li v-else-if="!options || (options && options.length === 0)" class="p-multiselect-empty-message" role="option">
                    <slot name="empty">{{ emptyMessageText }}</slot>
                  </li>
                </ul>
                <span v-if="!options || (options && options.length === 0)" role="status" aria-live="polite" class="p-hidden-accessible">
                  {{ emptyMessageText }}
                </span>
                <span role="status" aria-live="polite" class="p-hidden-accessible">
                  {{ selectedMessageText }}
                </span>
              </template>
              <template v-if="$slots.loader" #loader="{ options }">
                <slot name="loader" :options="options"></slot>
              </template>
            </VirtualScroller>
          </div>
          <slot name="footer" :value="modelValue" :options="visibleOptions"></slot>
          <span
            ref="lastHiddenFocusableElementOnOverlay"
            role="presentation"
            aria-hidden="true"
            class="p-hidden-accessible p-hidden-focusable"
            :tabindex="0"
            @focus="onLastHiddenFocus"
          ></span>
        </div>
      </transition>
    </Portal>
  </div>
</template>

<script>
import { ConnectedOverlayScrollHandler, UniqueComponentId, ObjectUtils, DomHandler, ZIndexUtils } from 'primevue/utils'
import OverlayEventBus from 'primevue/overlayeventbus'
import { FilterService } from 'primevue/api'
import Ripple from 'primevue/ripple'
import VirtualScroller from 'primevue/virtualscroller'
import Portal from 'primevue/portal'

export default {
  name: 'MultiSelect',
  directives: {
    ripple: Ripple,
  },
  components: {
    VirtualScroller: VirtualScroller,
    Portal: Portal,
  },
  props: {
    modelValue: null,
    options: Array,
    optionLabel: null,
    optionValue: null,
    optionDisabled: null,
    optionGroupLabel: null,
    optionGroupChildren: null,
    scrollHeight: {
      type: String,
      default: '200px',
    },
    placeholder: String,
    disabled: Boolean,
    inputId: {
      type: String,
      default: null,
    },
    inputProps: {
      type: null,
      default: null,
    },
    panelClass: {
      type: String,
      default: null,
    },
    panelStyle: {
      type: null,
      default: null,
    },
    panelProps: {
      type: null,
      default: null,
    },
    filterInputProps: {
      type: null,
      default: null,
    },
    closeButtonProps: {
      type: null,
      default: null,
    },
    dataKey: null,
    filter: Boolean,
    filterPlaceholder: String,
    filterLocale: String,
    filterMatchMode: {
      type: String,
      default: 'contains',
    },
    filterFields: {
      type: Array,
      default: null,
    },
    appendTo: {
      type: String,
      default: 'body',
    },
    display: {
      type: String,
      default: 'comma',
    },
    selectedItemsLabel: {
      type: String,
      default: '{0} items selected',
    },
    maxSelectedLabels: {
      type: Number,
      default: null,
    },
    selectionLimit: {
      type: Number,
      default: null,
    },
    showToggleAll: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    ajaxSearch: {
      type: Boolean,
      default: false,
    },
    loadingIcon: {
      type: String,
      default: 'pi pi-spinner pi-spin',
    },
    selectAll: {
      type: Boolean,
      default: null,
    },
    resetFilterOnHide: {
      type: Boolean,
      default: false,
    },
    virtualScrollerOptions: {
      type: Object,
      default: null,
    },
    autoOptionFocus: {
      type: Boolean,
      default: true,
    },
    autoFilterFocus: {
      type: Boolean,
      default: false,
    },
    filterMessage: {
      type: String,
      default: null,
    },
    selectionMessage: {
      type: String,
      default: null,
    },
    emptySelectionMessage: {
      type: String,
      default: null,
    },
    emptyFilterMessage: {
      type: String,
      default: null,
    },
    emptyMessage: {
      type: String,
      default: null,
    },
    tabindex: {
      type: Number,
      default: 0,
    },
    'aria-label': {
      type: String,
      default: null,
    },
    'aria-labelledby': {
      type: String,
      default: null,
    },
  },
  emits: [
    'update:modelValue',
    'change',
    'focus',
    'blur',
    'before-show',
    'before-hide',
    'show',
    'hide',
    'filter',
    'selectall-change',
    'wrapperScroll',
  ],
  outsideClickListener: null,
  scrollHandler: null,
  resizeListener: null,
  overlay: null,
  list: null,
  virtualScroller: null,
  startRangeIndex: -1,
  searchTimeout: null,
  searchValue: '',
  selectOnFocus: false,
  focusOnHover: false,
  data() {
    return {
      focused: false,
      focusedOptionIndex: -1,
      headerCheckboxFocused: false,
      filterValue: null,
      overlayVisible: false,
    }
  },
  computed: {
    containerClass() {
      return [
        'p-multiselect p-component p-inputwrapper',
        {
          'p-multiselect-chip': this.display === 'chip',
          'p-disabled': this.disabled,
          'p-focus': this.focused,
          'p-inputwrapper-filled': this.modelValue && this.modelValue.length,
          'p-inputwrapper-focus': this.focused || this.overlayVisible,
          'p-overlay-open': this.overlayVisible,
        },
      ]
    },
    labelClass() {
      return [
        'p-multiselect-label',
        {
          'p-placeholder': this.label === this.placeholder,
          'p-multiselect-label-empty': !this.placeholder && (!this.modelValue || this.modelValue.length === 0),
        },
      ]
    },
    dropdownIconClass() {
      return ['p-multiselect-trigger-icon', this.loading ? this.loadingIcon : 'pi pi-chevron-down']
    },
    panelStyleClass() {
      return [
        'p-multiselect-panel p-component',
        this.panelClass,
        {
          'p-input-filled': this.$primevue.config.inputStyle === 'filled',
          'p-ripple-disabled': this.$primevue.config.ripple === false,
        },
      ]
    },
    headerCheckboxClass() {
      return [
        'p-checkbox p-component',
        {
          'p-checkbox-checked': this.allSelected,
          'p-checkbox-focused': this.headerCheckboxFocused,
        },
      ]
    },
    visibleOptions() {
      const options = this.optionGroupLabel ? this.flatOptions(this.options) : this.options || []
      if (this.ajaxSearch) return options
      return this.filterValue ? FilterService.filter(options, this.searchFields, this.filterValue, this.filterMatchMode, this.filterLocale) : options
    },
    label() {
      // TODO: Refactor
      let label

      if (this.modelValue && this.modelValue.length) {
        if (ObjectUtils.isNotEmpty(this.maxSelectedLabels) && this.modelValue.length > this.maxSelectedLabels) {
          return this.getSelectedItemsLabel()
        } else {
          label = ''

          for (let i = 0; i < this.modelValue.length; i++) {
            if (i !== 0) {
              label += ', '
            }

            label += this.getLabelByValue(this.modelValue[i])
          }
        }
      } else {
        label = this.placeholder
      }

      return label
    },
    allSelected() {
      return this.selectAll !== null
        ? this.selectAll
        : ObjectUtils.isNotEmpty(this.visibleOptions) &&
            this.visibleOptions.every((option) => this.isOptionGroup(option) || this.isValidSelectedOption(option))
    },
    hasSelectedOption() {
      return ObjectUtils.isNotEmpty(this.modelValue)
    },
    equalityKey() {
      return this.optionValue ? null : this.dataKey
    },
    searchFields() {
      return this.filterFields || [this.optionLabel]
    },
    maxSelectionLimitReached() {
      return this.selectionLimit && this.modelValue && this.modelValue.length === this.selectionLimit
    },
    filterResultMessageText() {
      return ObjectUtils.isNotEmpty(this.visibleOptions)
        ? this.filterMessageText.replaceAll('{0}', this.visibleOptions.length)
        : this.emptyFilterMessageText
    },
    filterMessageText() {
      return this.filterMessage || this.$primevue.config.locale.searchMessage || ''
    },
    emptyFilterMessageText() {
      return this.emptyFilterMessage || this.$primevue.config.locale.emptySearchMessage || this.$primevue.config.locale.emptyFilterMessage || ''
    },
    emptyMessageText() {
      return this.emptyMessage || this.$primevue.config.locale.emptyMessage || ''
    },
    selectionMessageText() {
      return this.selectionMessage || this.$primevue.config.locale.selectionMessage || ''
    },
    emptySelectionMessageText() {
      return this.emptySelectionMessage || this.$primevue.config.locale.emptySelectionMessage || ''
    },
    selectedMessageText() {
      return this.hasSelectedOption ? this.selectionMessageText.replaceAll('{0}', this.modelValue.length) : this.emptySelectionMessageText
    },
    id() {
      return this.$attrs.id || UniqueComponentId()
    },
    focusedOptionId() {
      return this.focusedOptionIndex !== -1 ? `${this.id}_${this.focusedOptionIndex}` : null
    },
    ariaSetSize() {
      return this.visibleOptions.filter((option) => !this.isOptionGroup(option)).length
    },
    toggleAllAriaLabel() {
      return this.$primevue.config.locale.aria ? this.$primevue.config.locale.aria[this.allSelected ? 'selectAll' : 'unselectAll'] : undefined
    },
    closeAriaLabel() {
      return this.$primevue.config.locale.aria ? this.$primevue.config.locale.aria.close : undefined
    },
    virtualScrollerDisabled() {
      return !this.virtualScrollerOptions
    },
  },
  watch: {
    options() {
      this.autoUpdateModel()
    },
  },
  mounted() {
    this.autoUpdateModel()
  },
  beforeUnmount() {
    this.unbindOutsideClickListener()
    this.unbindResizeListener()

    if (this.scrollHandler) {
      this.scrollHandler.destroy()
      this.scrollHandler = null
    }

    if (this.overlay) {
      ZIndexUtils.clear(this.overlay)
      this.overlay = null
    }
  },
  methods: {
    handleWrapperScroll(e) {
      this.$emit('wrapperScroll', e)
    },
    getOptionIndex(index, fn) {
      return this.virtualScrollerDisabled ? index : fn && fn(index)['index']
    },
    getOptionLabel(option) {
      return this.optionLabel ? ObjectUtils.resolveFieldData(option, this.optionLabel) : option
    },
    getOptionValue(option) {
      return this.optionValue ? ObjectUtils.resolveFieldData(option, this.optionValue) : option
    },
    getOptionRenderKey(option) {
      return this.dataKey ? ObjectUtils.resolveFieldData(option, this.dataKey) : this.getOptionLabel(option)
    },
    isOptionDisabled(option) {
      if (this.maxSelectionLimitReached && !this.isSelected(option)) {
        return true
      }

      return this.optionDisabled ? ObjectUtils.resolveFieldData(option, this.optionDisabled) : false
    },
    isOptionGroup(option) {
      return this.optionGroupLabel && option.optionGroup && option.group
    },
    getOptionGroupLabel(optionGroup) {
      return ObjectUtils.resolveFieldData(optionGroup, this.optionGroupLabel)
    },
    getOptionGroupChildren(optionGroup) {
      return ObjectUtils.resolveFieldData(optionGroup, this.optionGroupChildren)
    },
    getAriaPosInset(index) {
      return (this.optionGroupLabel ? index - this.visibleOptions.slice(0, index).filter((option) => this.isOptionGroup(option)).length : index) + 1
    },
    show(isFocus) {
      this.$emit('before-show')
      this.overlayVisible = true
      this.focusedOptionIndex =
        this.focusedOptionIndex !== -1 ? this.focusedOptionIndex : this.autoOptionFocus ? this.findFirstFocusedOptionIndex() : -1

      isFocus && DomHandler.focus(this.$refs.focusInput)
    },
    hide(isFocus) {
      this.$emit('before-hide')
      this.overlayVisible = false
      this.focusedOptionIndex = -1
      this.searchValue = ''

      this.resetFilterOnHide && (this.filterValue = null)
      isFocus && DomHandler.focus(this.$refs.focusInput)
    },
    onFocus(event) {
      this.focused = true
      this.focusedOptionIndex = this.overlayVisible && this.autoOptionFocus ? this.findFirstFocusedOptionIndex() : -1
      this.overlayVisible && this.scrollInView(this.focusedOptionIndex)
      this.$emit('focus', event)
    },
    onBlur(event) {
      this.focused = false
      this.focusedOptionIndex = -1
      this.searchValue = ''
      this.$emit('blur', event)
    },
    onKeyDown(event) {
      const metaKey = event.metaKey || event.ctrlKey

      switch (event.code) {
        case 'ArrowDown':
          this.onArrowDownKey(event)
          break

        case 'ArrowUp':
          this.onArrowUpKey(event)
          break

        case 'Home':
          this.onHomeKey(event)
          break

        case 'End':
          this.onEndKey(event)
          break

        case 'PageDown':
          this.onPageDownKey(event)
          break

        case 'PageUp':
          this.onPageUpKey(event)
          break

        case 'Enter':
        case 'Space':
          this.onEnterKey(event)
          break

        case 'Escape':
          this.onEscapeKey(event)
          break

        case 'Tab':
          this.onTabKey(event)
          break

        case 'ShiftLeft':
        case 'ShiftRight':
          this.onShiftKey(event)
          break

        default:
          if (event.code === 'KeyA' && metaKey) {
            const value = this.visibleOptions.filter((option) => this.isValidOption(option)).map((option) => this.getOptionValue(option))

            this.updateModel(event, value)

            event.preventDefault()
            break
          }

          if (!metaKey && ObjectUtils.isPrintableCharacter(event.key)) {
            !this.overlayVisible && this.show()
            this.searchOptions(event)
            event.preventDefault()
          }

          break
      }
    },
    onContainerClick(event) {
      if (this.disabled || this.loading) {
        return
      }

      if (!this.overlay || !this.overlay.contains(event.target)) {
        this.overlayVisible ? this.hide(true) : this.show(true)
      }
    },
    onFirstHiddenFocus(event) {
      const relatedTarget = event.relatedTarget

      if (relatedTarget === this.$refs.focusInput) {
        const firstFocusableEl = DomHandler.getFirstFocusableElement(this.overlay, ':not(.p-hidden-focusable)')

        DomHandler.focus(firstFocusableEl)
      } else {
        DomHandler.focus(this.$refs.focusInput)
      }
    },
    onLastHiddenFocus() {
      DomHandler.focus(this.$refs.firstHiddenFocusableElementOnOverlay)
    },
    onCloseClick() {
      this.hide(true)
    },
    onHeaderCheckboxFocus() {
      this.headerCheckboxFocused = true
    },
    onHeaderCheckboxBlur() {
      this.headerCheckboxFocused = false
    },
    onOptionSelect(event, option, index = -1, isFocus = false) {
      if (this.disabled || this.isOptionDisabled(option)) {
        return
      }

      let selected = this.isSelected(option)
      let value = null

      if (selected) value = this.modelValue.filter((val) => !ObjectUtils.equals(val, this.getOptionValue(option), this.equalityKey))
      else value = [...(this.modelValue || []), this.getOptionValue(option)]

      this.updateModel(event, value)
      isFocus && DomHandler.focus(this.$refs.focusInput)
      index !== -1 && (this.focusedOptionIndex = index)
    },
    onOptionMouseMove(event, index) {
      if (this.focusOnHover) {
        this.changeFocusedOptionIndex(event, index)
      }
    },
    onOptionSelectRange(event, start = -1, end = -1) {
      start === -1 && (start = this.findNearestSelectedOptionIndex(end, true))
      end === -1 && (end = this.findNearestSelectedOptionIndex(start))

      if (start !== -1 && end !== -1) {
        const rangeStart = Math.min(start, end)
        const rangeEnd = Math.max(start, end)
        const value = this.visibleOptions
          .slice(rangeStart, rangeEnd + 1)
          .filter((option) => this.isValidOption(option))
          .map((option) => this.getOptionValue(option))

        this.updateModel(event, value)
      }
    },
    onFilterChange(event) {
      const value = event.target.value

      this.filterValue = value
      this.focusedOptionIndex = -1
      this.$emit('filter', { originalEvent: event, value })

      !this.virtualScrollerDisabled && this.virtualScroller.scrollToIndex(0)
    },
    onFilterKeyDown(event) {
      switch (event.code) {
        case 'ArrowDown':
          this.onArrowDownKey(event)
          break

        case 'ArrowUp':
          this.onArrowUpKey(event, true)
          break

        case 'ArrowLeft':
        case 'ArrowRight':
          this.onArrowLeftKey(event, true)
          break

        case 'Home':
          this.onHomeKey(event, true)
          break

        case 'End':
          this.onEndKey(event, true)
          break

        case 'Enter':
          this.onEnterKey(event)
          break

        case 'Escape':
          this.onEscapeKey(event)
          break

        case 'Tab':
          this.onTabKey(event, true)
          break

        default:
          break
      }
    },
    onFilterBlur() {
      this.focusedOptionIndex = -1
    },
    onFilterUpdated() {
      if (this.overlayVisible) {
        this.alignOverlay()
      }
    },
    onOverlayClick(event) {
      OverlayEventBus.emit('overlay-click', {
        originalEvent: event,
        target: this.$el,
      })
    },
    onOverlayKeyDown(event) {
      switch (event.code) {
        case 'Escape':
          this.onEscapeKey(event)
          break

        default:
          break
      }
    },
    onArrowDownKey(event) {
      const optionIndex = this.focusedOptionIndex !== -1 ? this.findNextOptionIndex(this.focusedOptionIndex) : this.findFirstFocusedOptionIndex()

      if (event.shiftKey) {
        this.onOptionSelectRange(event, this.startRangeIndex, optionIndex)
      }

      this.changeFocusedOptionIndex(event, optionIndex)

      !this.overlayVisible && this.show()
      event.preventDefault()
    },
    onArrowUpKey(event, pressedInInputText = false) {
      if (event.altKey && !pressedInInputText) {
        if (this.focusedOptionIndex !== -1) {
          this.onOptionSelect(event, this.visibleOptions[this.focusedOptionIndex])
        }

        this.overlayVisible && this.hide()
        event.preventDefault()
      } else {
        const optionIndex = this.focusedOptionIndex !== -1 ? this.findPrevOptionIndex(this.focusedOptionIndex) : this.findLastFocusedOptionIndex()

        if (event.shiftKey) {
          this.onOptionSelectRange(event, optionIndex, this.startRangeIndex)
        }

        this.changeFocusedOptionIndex(event, optionIndex)

        !this.overlayVisible && this.show()
        event.preventDefault()
      }
    },
    onArrowLeftKey(event, pressedInInputText = false) {
      pressedInInputText && (this.focusedOptionIndex = -1)
    },
    onHomeKey(event, pressedInInputText = false) {
      if (pressedInInputText) {
        event.currentTarget.setSelectionRange(0, 0)
        this.focusedOptionIndex = -1
      } else {
        let metaKey = event.metaKey || event.ctrlKey
        let optionIndex = this.findFirstOptionIndex()

        if (event.shiftKey && metaKey) {
          this.onOptionSelectRange(event, optionIndex, this.startRangeIndex)
        }

        this.changeFocusedOptionIndex(event, optionIndex)

        !this.overlayVisible && this.show()
      }

      event.preventDefault()
    },
    onEndKey(event, pressedInInputText = false) {
      if (pressedInInputText) {
        const target = event.currentTarget
        const len = target.value.length

        target.setSelectionRange(len, len)
        this.focusedOptionIndex = -1
      } else {
        let metaKey = event.metaKey || event.ctrlKey
        let optionIndex = this.findLastOptionIndex()

        if (event.shiftKey && metaKey) {
          this.onOptionSelectRange(event, this.startRangeIndex, optionIndex)
        }

        this.changeFocusedOptionIndex(event, optionIndex)

        !this.overlayVisible && this.show()
      }

      event.preventDefault()
    },
    onPageUpKey(event) {
      this.scrollInView(0)
      event.preventDefault()
    },
    onPageDownKey(event) {
      this.scrollInView(this.visibleOptions.length - 1)
      event.preventDefault()
    },
    onEnterKey(event) {
      if (!this.overlayVisible) {
        this.onArrowDownKey(event)
      } else {
        if (this.focusedOptionIndex !== -1) {
          if (event.shiftKey) this.onOptionSelectRange(event, this.focusedOptionIndex)
          else this.onOptionSelect(event, this.visibleOptions[this.focusedOptionIndex])
        }
      }

      event.preventDefault()
    },
    onEscapeKey(event) {
      this.overlayVisible && this.hide(true)
      event.preventDefault()
    },
    onTabKey(event, pressedInInputText = false) {
      if (!pressedInInputText) {
        if (this.overlayVisible && this.hasFocusableElements()) {
          DomHandler.focus(this.$refs.firstHiddenFocusableElementOnOverlay)

          event.preventDefault()
        } else {
          if (this.focusedOptionIndex !== -1) {
            this.onOptionSelect(event, this.visibleOptions[this.focusedOptionIndex])
          }

          this.overlayVisible && this.hide(this.filter)
        }
      }
    },
    onShiftKey() {
      this.startRangeIndex = this.focusedOptionIndex
    },
    onOverlayEnter(el) {
      ZIndexUtils.set('overlay', el, this.$primevue.config.zIndex.overlay)
      this.alignOverlay()
      this.scrollInView()

      this.autoFilterFocus && DomHandler.focus(this.$refs.filterInput)
    },
    onOverlayAfterEnter() {
      this.bindOutsideClickListener()
      this.bindScrollListener()
      this.bindResizeListener()

      this.$emit('show')
    },
    onOverlayLeave() {
      this.unbindOutsideClickListener()
      this.unbindScrollListener()
      this.unbindResizeListener()

      this.$emit('hide')
      this.overlay = null
    },
    onOverlayAfterLeave(el) {
      ZIndexUtils.clear(el)
    },
    alignOverlay() {
      if (this.appendTo === 'self') {
        DomHandler.relativePosition(this.overlay, this.$el)
      } else {
        this.overlay.style.minWidth = DomHandler.getOuterWidth(this.$el) + 'px'
        DomHandler.absolutePosition(this.overlay, this.$el)
      }
    },
    bindOutsideClickListener() {
      if (!this.outsideClickListener) {
        this.outsideClickListener = (event) => {
          if (this.overlayVisible && this.isOutsideClicked(event)) {
            this.hide()
          }
        }

        document.addEventListener('click', this.outsideClickListener)
      }
    },
    unbindOutsideClickListener() {
      if (this.outsideClickListener) {
        document.removeEventListener('click', this.outsideClickListener)
        this.outsideClickListener = null
      }
    },
    bindScrollListener() {
      if (!this.scrollHandler) {
        this.scrollHandler = new ConnectedOverlayScrollHandler(this.$refs.container, () => {
          if (this.overlayVisible) {
            this.hide()
          }
        })
      }

      this.scrollHandler.bindScrollListener()
    },
    unbindScrollListener() {
      if (this.scrollHandler) {
        this.scrollHandler.unbindScrollListener()
      }
    },
    bindResizeListener() {
      if (!this.resizeListener) {
        this.resizeListener = () => {
          if (this.overlayVisible && !DomHandler.isTouchDevice()) {
            this.hide()
          }
        }

        window.addEventListener('resize', this.resizeListener)
      }
    },
    unbindResizeListener() {
      if (this.resizeListener) {
        window.removeEventListener('resize', this.resizeListener)
        this.resizeListener = null
      }
    },
    isOutsideClicked(event) {
      return !(this.$el.isSameNode(event.target) || this.$el.contains(event.target) || (this.overlay && this.overlay.contains(event.target)))
    },
    getLabelByValue(value) {
      const options = this.optionGroupLabel ? this.flatOptions(this.options) : this.options || []
      const matchedOption = options.find(
        (option) => !this.isOptionGroup(option) && ObjectUtils.equals(this.getOptionValue(option), value, this.equalityKey)
      )

      return matchedOption ? this.getOptionLabel(matchedOption) : null
    },
    getSelectedItemsLabel() {
      let pattern = /{(.*?)}/

      if (pattern.test(this.selectedItemsLabel)) {
        return this.selectedItemsLabel.replace(this.selectedItemsLabel.match(pattern)[0], this.modelValue.length + '')
      }

      return this.selectedItemsLabel
    },
    onToggleAll(event) {
      if (this.selectAll !== null) {
        this.$emit('selectall-change', { originalEvent: event, checked: !this.allSelected })
      } else {
        const value = this.allSelected
          ? []
          : this.visibleOptions.filter((option) => !this.isOptionGroup(option)).map((option) => this.getOptionValue(option))

        this.updateModel(event, value)
      }

      this.headerCheckboxFocused = true
    },
    removeOption(event, optionValue) {
      let value = this.modelValue.filter((val) => !ObjectUtils.equals(val, optionValue, this.equalityKey))

      this.updateModel(event, value)
    },
    clearFilter() {
      this.filterValue = null
    },
    hasFocusableElements() {
      return DomHandler.getFocusableElements(this.overlay, ':not(.p-hidden-focusable)').length > 0
    },
    isOptionMatched(option) {
      return (
        this.isValidOption(option) &&
        this.getOptionLabel(option).toLocaleLowerCase(this.filterLocale).startsWith(this.searchValue.toLocaleLowerCase(this.filterLocale))
      )
    },
    isValidOption(option) {
      return option && !(this.isOptionDisabled(option) || this.isOptionGroup(option))
    },
    isValidSelectedOption(option) {
      return this.isValidOption(option) && this.isSelected(option)
    },
    isSelected(option) {
      const optionValue = this.getOptionValue(option)

      return (this.modelValue || []).some((value) => ObjectUtils.equals(value, optionValue, this.equalityKey))
    },
    findFirstOptionIndex() {
      return this.visibleOptions.findIndex((option) => this.isValidOption(option))
    },
    findLastOptionIndex() {
      return ObjectUtils.findLastIndex(this.visibleOptions, (option) => this.isValidOption(option))
    },
    findNextOptionIndex(index) {
      const matchedOptionIndex =
        index < this.visibleOptions.length - 1 ? this.visibleOptions.slice(index + 1).findIndex((option) => this.isValidOption(option)) : -1

      return matchedOptionIndex > -1 ? matchedOptionIndex + index + 1 : index
    },
    findPrevOptionIndex(index) {
      const matchedOptionIndex =
        index > 0 ? ObjectUtils.findLastIndex(this.visibleOptions.slice(0, index), (option) => this.isValidOption(option)) : -1

      return matchedOptionIndex > -1 ? matchedOptionIndex : index
    },
    findFirstSelectedOptionIndex() {
      return this.hasSelectedOption ? this.visibleOptions.findIndex((option) => this.isValidSelectedOption(option)) : -1
    },
    findLastSelectedOptionIndex() {
      return this.hasSelectedOption ? ObjectUtils.findLastIndex(this.visibleOptions, (option) => this.isValidSelectedOption(option)) : -1
    },
    findNextSelectedOptionIndex(index) {
      const matchedOptionIndex =
        this.hasSelectedOption && index < this.visibleOptions.length - 1
          ? this.visibleOptions.slice(index + 1).findIndex((option) => this.isValidSelectedOption(option))
          : -1

      return matchedOptionIndex > -1 ? matchedOptionIndex + index + 1 : -1
    },
    findPrevSelectedOptionIndex(index) {
      const matchedOptionIndex =
        this.hasSelectedOption && index > 0
          ? ObjectUtils.findLastIndex(this.visibleOptions.slice(0, index), (option) => this.isValidSelectedOption(option))
          : -1

      return matchedOptionIndex > -1 ? matchedOptionIndex : -1
    },
    findNearestSelectedOptionIndex(index, firstCheckUp = false) {
      let matchedOptionIndex = -1

      if (this.hasSelectedOption) {
        if (firstCheckUp) {
          matchedOptionIndex = this.findPrevSelectedOptionIndex(index)
          matchedOptionIndex = matchedOptionIndex === -1 ? this.findNextSelectedOptionIndex(index) : matchedOptionIndex
        } else {
          matchedOptionIndex = this.findNextSelectedOptionIndex(index)
          matchedOptionIndex = matchedOptionIndex === -1 ? this.findPrevSelectedOptionIndex(index) : matchedOptionIndex
        }
      }

      return matchedOptionIndex > -1 ? matchedOptionIndex : index
    },
    findFirstFocusedOptionIndex() {
      const selectedIndex = this.findFirstSelectedOptionIndex()

      return selectedIndex < 0 ? this.findFirstOptionIndex() : selectedIndex
    },
    findLastFocusedOptionIndex() {
      const selectedIndex = this.findLastSelectedOptionIndex()

      return selectedIndex < 0 ? this.findLastOptionIndex() : selectedIndex
    },
    searchOptions(event) {
      this.searchValue = (this.searchValue || '') + event.key

      let optionIndex = -1

      if (this.focusedOptionIndex !== -1) {
        optionIndex = this.visibleOptions.slice(this.focusedOptionIndex).findIndex((option) => this.isOptionMatched(option))
        optionIndex =
          optionIndex === -1
            ? this.visibleOptions.slice(0, this.focusedOptionIndex).findIndex((option) => this.isOptionMatched(option))
            : optionIndex + this.focusedOptionIndex
      } else {
        optionIndex = this.visibleOptions.findIndex((option) => this.isOptionMatched(option))
      }

      if (optionIndex === -1 && this.focusedOptionIndex === -1) {
        const selectedIndex = this.findSelectedOptionIndex()

        optionIndex = selectedIndex < 0 ? this.findFirstOptionIndex() : selectedIndex
      }

      if (optionIndex !== -1) {
        this.changeFocusedOptionIndex(event, optionIndex)
      }

      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout)
      }

      this.searchTimeout = setTimeout(() => {
        this.searchValue = ''
        this.searchTimeout = null
      }, 500)
    },
    changeFocusedOptionIndex(event, index) {
      if (this.focusedOptionIndex !== index) {
        this.focusedOptionIndex = index
        this.scrollInView()
      }
    },
    scrollInView(index = -1) {
      const id = index !== -1 ? `${this.id}_${index}` : this.focusedOptionId
      const element = DomHandler.findSingle(this.list, `li[id="${id}"]`)

      if (element) {
        element.scrollIntoView && element.scrollIntoView({ block: 'nearest', inline: 'nearest' })
      } else if (!this.virtualScrollerDisabled) {
        this.virtualScroller && this.virtualScroller.scrollToIndex(index !== -1 ? index : this.focusedOptionIndex)
      }
    },
    autoUpdateModel() {
      if (this.selectOnFocus && this.autoOptionFocus && !this.hasSelectedOption) {
        this.focusedOptionIndex = this.findFirstFocusedOptionIndex()
        const value = this.getOptionValue(this.visibleOptions[this.focusedOptionIndex])

        this.updateModel(null, [value])
      }
    },
    updateModel(event, value) {
      this.$emit('update:modelValue', value)
      this.$emit('change', { originalEvent: event, value })
    },
    flatOptions(options) {
      return (options || []).reduce((result, option, index) => {
        result.push({ optionGroup: option, group: true, index })

        const optionGroupChildren = this.getOptionGroupChildren(option)

        optionGroupChildren && optionGroupChildren.forEach((o) => result.push(o))

        return result
      }, [])
    },
    overlayRef(el) {
      this.overlay = el
    },
    listRef(el, contentRef) {
      this.list = el
      contentRef && contentRef(el) // For VirtualScroller
    },
    virtualScrollerRef(el) {
      this.virtualScroller = el
    },
  },
}
</script>

<style>
.p-multiselect {
  display: inline-flex;
  cursor: pointer;
  position: relative;
  user-select: none;
}

.p-multiselect-trigger {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.p-multiselect-label-container {
  overflow: hidden;
  flex: 1 1 auto;
  cursor: pointer;
}

.p-multiselect-label {
  display: block;
  white-space: nowrap;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
}

.p-multiselect-label-empty {
  overflow: hidden;
  visibility: hidden;
}

.p-multiselect-token {
  cursor: default;
  display: inline-flex;
  align-items: center;
  flex: 0 0 auto;
}

.p-multiselect-token-icon {
  cursor: pointer;
}

.p-multiselect .p-multiselect-panel {
  min-width: 100%;
}

.p-multiselect-panel {
  position: absolute;
  top: 0;
  left: 0;
}

.p-multiselect-items-wrapper {
  overflow: auto;
}

.p-multiselect-items {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.p-multiselect-item {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: normal;
  white-space: nowrap;
  position: relative;
  overflow: hidden;
}

.p-multiselect-item-group {
  cursor: auto;
}

.p-multiselect-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.p-multiselect-filter-container {
  position: relative;
  flex: 1 1 auto;
}

.p-multiselect-filter-icon {
  position: absolute;
  top: 50%;
  margin-top: -0.5rem;
}

.p-multiselect-filter-container .p-inputtext {
  width: 100%;
}

.p-multiselect-close {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  overflow: hidden;
  position: relative;
  margin-left: auto;
}

.p-fluid .p-multiselect {
  display: flex;
}
</style>
