<template>
  <div class="layout-footer">
    <img :src="logo" alt="Ea Team logo" style="width: 125px" />
  </div>
</template>

<script>
export default {
  name: 'AppFooter',
  computed: {
    darkTheme() {
      return this.$appState.darkTheme
    },
    logo() {
      return this.$store.state.imagesUrl + '/' + this.$store.state.tenant.logo
    },
  },
  methods: {
    footerImage() {
      return this.$appState.darkTheme ? 'images/logo-white.svg' : 'images/logo-dark.svg'
    },
  },
}
</script>