const state = {
  admins: [],
  adminMetaData: [],
}
const getters = {
  adminsGetter: ({ admins }) => admins,
  adminMetaDataGetter: ({ adminMetaData }) => adminMetaData,
}

const mutations = {
  setAdmins: (state, payload) => (state.admins = payload),
  setAdmin: (state, payload) => state.admins.push(payload),
  setAdminMetaData: (state, payload) => (state.adminMetaData = payload),
  setUpdateAdmin: (state, payload) => {
    state.admins = state.admins.map((admin) => {
      if (admin.id === payload.id) {
        return Object.assign({}, admin, payload)
      }
      return admin
    })
  },
  setDeleteAdmin: (state, payload) => {
    state.admins = state.admins.filter((admin) => admin.id !== payload)
  },
}

const actions = {
  async createAdmin({ commit }, payload) {
    await this.$axios.post('admins', payload).then((res) => {
      commit('setAdmin', res.data.result)
    })
  },
  async getAdmins({ commit }, payload) {
    await this.$axios.get('admins', { params: { ...payload } }).then((res) => {
      commit('setAdminMetaData', res.data.result.metadata)
      commit('setAdmins', res.data.result.items)
    })
  },
  async updateAdmin({ commit }, payload) {
    await this.$axios.put(`admins/${payload.id}`, payload).then((res) => {
      commit('setUpdateAdmin', payload)
    })
  },
  deleteAdmin({ commit }, payload) {
    this.$axios.delete(`admins/${payload}`).then((res) => {
      commit('setDeleteAdmin', payload)
    })
  },
}
export default { state, getters, mutations, actions }
