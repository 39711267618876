const state = {
  groups: [],
  groupMetaData: [],
}
const getters = {
  groupsGetter: ({ groups }) => groups,
  groupMetaDataGetter: ({ groupMetaData }) => groupMetaData,
}

const mutations = {
  setGroups: (state, payload) => (state.groups = payload),
  setBulkGroups: (state, payload) => state.groups.push(...payload),
  setGroup: (state, payload) => state.groups.push(payload),
  setGroupMetaData: (state, payload) => (state.groupMetaData = payload),
  setUpdateGroup: (state, payload) => {
    state.groups = state.groups.map((group) => {
      if (group.id === payload.id) {
        return Object.assign({}, group, payload)
      }
      return group
    })
  },
  setDeleteGroup: (state, payload) => {
    console.log(typeof payload)
    state.groups = state.groups.filter((group) => group.id !== payload)
  },
}

const actions = {
  async createGroup({ commit }, payload) {
    await this.$axios.post('groups', payload).then((res) => {
      commit('setGroup', res.data.result)
    })
  },
  async getGroups({ commit }, payload) {
    await this.$axios.get('groups', { params: { ...payload.filters } }).then((res) => {
      commit('setGroupMetaData', res.data.result.metadata)
      if (payload && payload.bulk) commit('setBulkGroups', res.data.result.items)
      else commit('setGroups', res.data.result.items)
    })
  },
  async updateGroup({ commit }, payload) {
    await this.$axios.put(`groups/${payload.id}`, payload).then((res) => {
      commit('setUpdateGroup', payload)
    })
  },
  deleteGroup({ commit }, payload) {
    this.$axios.delete(`groups/${payload}`).then((res) => {
      commit('setDeleteGroup', payload)
    })
  },
}
export default { state, getters, mutations, actions }
