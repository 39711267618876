const state = {
  tasksAnalysis: {},
  moneyAnalysis: {},
  questionsAnalysis: {},
  tweetAnalysis: {},
  inviteAnalysis: {},
  ambassadorAnalysis: {},
}
const getters = {
  tasksAnalysis: ({ tasksAnalysis }) => tasksAnalysis,
  moneyAnalysis: ({ moneyAnalysis }) => moneyAnalysis,
  questionsAnalysis: ({ questionsAnalysis }) => questionsAnalysis,
  tweetAnalysis: ({ tweetAnalysis }) => tweetAnalysis,
  inviteAnalysis: ({ inviteAnalysis }) => inviteAnalysis,
  ambassadorAnalysis: ({ ambassadorAnalysis }) => ambassadorAnalysis,
}
const mutations = {
  setTasksAnalysis: (state, payload) => (state.tasksAnalysis = payload),
  setMoneyAnalysis: (state, payload) => (state.moneyAnalysis = payload),
  setQuestionsAnalysis: (state, payload) => (state.questionsAnalysis = payload),
  setTweetAnalysis: (state, payload) => (state.tweetAnalysis = payload),
  setInviteAnalysis: (state, payload) => (state.inviteAnalysis = payload),
  setAmbassadorAnalysis: (state, payload) => (state.ambassadorAnalysis = payload),
}
const actions = {
  async getTasksAnalysis({ commit }) {
    await this.$axios.get('dashboard/tasks-analysis').then((res) => {
      commit('setTasksAnalysis', res.data.result.data_sets)
    })
  },
  async getMoneyAnalysis({ commit }) {
    await this.$axios.get('dashboard/money-analysis').then((res) => {
      commit('setMoneyAnalysis', res.data.result.data_sets)
    })
  },
  async getQuestionsAnalysis({ commit }) {
    await this.$axios.get('dashboard/questions-analysis').then((res) => {
      commit('setQuestionsAnalysis', res.data.result)
    })
  },
  async getTweetAnalysis({ commit }) {
    await this.$axios.get('dashboard/tweet-analysis').then((res) => {
      commit('setTweetAnalysis', res.data.result)
    })
  },
  async getInviteAnalysis({ commit }) {
    await this.$axios.get('dashboard/invite-analysis').then((res) => {
      commit('setInviteAnalysis', res.data.result)
    })
  },
  async getAmbassadorAnalysis({ commit }) {
    await this.$axios.get('dashboard/ambassador-analysis').then((res) => {
      commit('setAmbassadorAnalysis', res.data.result.data_sets)
    })
  },
}

export default { state, getters, mutations, actions }
