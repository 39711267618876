const state = {
  invites: [],
  inviteMetaData: [],
}
const getters = {
  invitesGetter: ({ invites }) => invites,
  inviteMetaDataGetter: ({ inviteMetaData }) => inviteMetaData,
}

const mutations = {
  setInvites: (state, payload) => (state.invites = payload),
  setInvite: (state, payload) => state.invites.push(payload),
  setInviteMetaData: (state, payload) => (state.inviteMetaData = payload),
}

const actions = {
  async createInvite({ commit }, payload) {
    await this.$axios.post('invites', payload).then((res) => {
      commit('setInvite', res.data.result)
    })
  },
  async getInvites({ commit }, payload) {
    await this.$axios.get('invites', { params: { ...payload } }).then((res) => {
      commit('setInviteMetaData', res.data.result.metadata)
      commit('setInvites', res.data.result.items)
    })
  },
  updateInvite({ commit }, payload) {
    this.$axios.put(`invites/${payload.id}`, payload).then((res) => {
      console.log(res)
    })
  },
}
export default { state, getters, mutations, actions }
