const state = {
  permissions: [],
  permissionMetaData: [],
  rolePermissions: [],
}
const getters = {
  permissionsGetter: ({ permissions }) => permissions,
  rolePermissionsGetter: ({ rolePermissions }) => rolePermissions,
}

const mutations = {
  setPermissions: (state, payload) => (state.permissions = payload),
  setPermissionMetaData: (state, payload) => (state.permissionMetaData = payload),
  setRolePermissions: (state, payload) => (state.rolePermissions = payload),
}

const actions = {
  getPermissions({ commit }, payload) {
    this.$axios.get('permissions', { params: payload }).then((res) => {
      commit('setPermissionMetaData', res.data.result.metadata)
      commit('setPermissions', res.data.result.items)
    })
  },
  async getRolePermissions({ commit }, payload) {
    await this.$axios.get(`roles/${payload}/permissions`).then((res) => {
      commit('setRolePermissions', res.data.result)
    })
  },
  updateRolePermissions({ commit }, payload) {
    this.$axios.put(`roles/${payload.id}/permissions`, null, { params: { permissions: payload.permissions } }).then((res) => {})
  },
}
export default { state, getters, mutations, actions }
